import { ClaimMdGender } from '@innerwell/api-claim-md-types';
import { ProfilePayer } from '../profiles';

type PayerForInsuranceCdt = Pick<
   ProfilePayer,
   'pdtf-payer_id' | 'pdtf-primary_name'
> & {
   id: string;
};

export enum CdtInsuranceInfoSource {
   Patient = 'patient',
   Cron = 'cron',
   Ops = 'ops',
}
export type CdtInsuranceInfo = {
   'cdtf-plan-name'?: string | null;
   'cdtf-payer-id'?: string | null;
   'cdtf-payer-name'?: string | null;
   'cdtf-needs-manual-check'?: boolean;
   // These values might change.
   'cdtf-estimated-co-pay'?: number | null;
   'cdtf-estimated-co-insurance'?: number | null;
   'cdtf-database-entity-id'?: string;
   'cdtf-is-valid': boolean;
   'cdtf-insurance-expiration-date'?: string | null;
   'cdtf-co-pay'?: number | null;
   'cdtf-plan-type'?: 'clinician' | 'psychotherapist' | null;
   'cdtf-member-id'?: string | null;
   'cdtf-primary-subscriber'?: boolean | null;
   'cdtf-primary-subscriber-first-name'?: string | null;
   'cdtf-primary-subscriber-last-name'?: string | null;
   'cdtf-primary-subscriber-gender'?: ClaimMdGender | null;

   // Payer id for card
   'cdtf-payer-id-card'?: PayerForInsuranceCdt | null;
   // Payer id for claims
   'cdtf-payer-id-claims'?: PayerForInsuranceCdt | null;

   'cdtf-manually-recheck-eligibility'?: boolean | null;
   'cdtf-source': CdtInsuranceInfoSource;

   'cdtf-insurance-start-date': string;
   'cdtf-insurance-end-date'?: string | null;
};
