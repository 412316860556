import { z } from 'zod';
import { nmiMerchantFieldsSchema } from '../../nmi';

export const createOrderAndTransactionParamsDtoSchema = z.object({
   sourceTransactionId: z.string().optional(),
   merchantDefinedFields: nmiMerchantFieldsSchema.optional(),
   items: z.array(
      z.object({
         sku: z.string(),
         quantity: z.number(),
         totalAfterDiscount: z.number(),
         price: z.number(),
      }),
   ),
});

export type CreateOrderAndTransactionParamsDto = z.infer<
   typeof createOrderAndTransactionParamsDtoSchema
>;
