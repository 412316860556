import {
   type AppointmentEventData,
   type AppointmentUpdateErrorData,
   type AudioPlayerErrorEventData,
   type CheckoutAddressValidationErrorEventData,
   type CheckoutClickPayEventData,
   type CheckoutClickPaymentMethodEventData,
   type CheckoutClickPurchaseButtonEventData,
   type CheckoutDiscountCodeInputEventData,
   type CustomerIOEventMap,
   CustomerIOEvents,
   type DosingSessionStartedData,
   type InNetworkCheckData,
   type InitialScreeningCompletedBrowserWindowClosedEventData,
   type PatientStartedPurchaseFlowEventData,
   type PurchaseSelectProductEventData,
   type PurchaseUpsellClickAddTherapyEventData,
   type SignupScreenerRejectedData,
   type SignupScreenerStartedData,
   type SignupScreenerStepData,
   type SuccessfulPaymentEventFields,
   type TriageScreenerFinishedData,
   type TriageScreenerStartedData,
   type CancelAppointmentConfirmedEventData,
} from './customerio.types';
import 'reflect-metadata';
import { z } from 'zod';
import { phoneField } from '../common';

export const INSURANCE_STATUS = {
   Insured: 'insured',
   NotInsured: 'not_insured',
} as const;

export type InsuranceStatus =
   (typeof INSURANCE_STATUS)[keyof typeof INSURANCE_STATUS];

export const CIO_BOOLEAN_VALUE = {
   True: 'Y',
   False: 'N',
} as const;

export type CioBooleanValue =
   (typeof CIO_BOOLEAN_VALUE)[keyof typeof CIO_BOOLEAN_VALUE];

export const ELIGIBILITY_STATUS = {
   Eligible: 'eligible',
   NotEligible: 'not_eligible',
} as const;

export type EligibilityStatus =
   (typeof ELIGIBILITY_STATUS)[keyof typeof ELIGIBILITY_STATUS];

type BaseParams<EventType> = {
   name: EventType;
   type?: 'event' | 'page' | 'screen';
   timestamp?: number; // Unix timestamp, seconds
} & (EventType extends keyof CustomerIOEventMap
   ? { data: CustomerIOEventMap[EventType] }
   : unknown);

export type TrackEventParamsDto<EventType> = BaseParams<EventType> & {
   id?: string;
};

export const customerParamsSchema = z.object({
   first_name: z.string().optional(),
   phone_number: phoneField.optional(), //string
   state: z.string().optional(),
   product_options: z.string().optional(),
   timezone: z.string().optional(),
   formsort_uuid: z.string().uuid().optional(),
   insurance_status: z.nativeEnum(INSURANCE_STATUS).optional(),
   has_insurance_with_one_of_our_payers: z
      .nativeEnum(CIO_BOOLEAN_VALUE)
      .optional(),
   insurer_name: z.string().optional(),
   eligibility_status: z.nativeEnum(ELIGIBILITY_STATUS).optional(),
});

export type CustomerParams = z.infer<typeof customerParamsSchema>;

const defaultDataSchema = z.record(z.string(), z.unknown());

const defaultDataValidator = (data: unknown) => {
   return defaultDataSchema.safeParse(data).success;
};

// Validators for each data type.
// We want correct TS, but are not validating the data itself, except we want data to
// be an object with string keys and unknown values only.
// This is to prevent unnecessary validation errors without writing data to CIO. We're better of with
// partial data there than no data at all.
const appointmentEventDataSchema =
   z.custom<AppointmentEventData>(defaultDataValidator);
const appointmentUpdateErrorDataSchema =
   z.custom<AppointmentUpdateErrorData>(defaultDataValidator);
const signupScreenerStartedDataSchema =
   z.custom<SignupScreenerStartedData>(defaultDataValidator);
const signupScreenerStepDataSchema =
   z.custom<SignupScreenerStepData>(defaultDataValidator);
const signupScreenerRejectedDataSchema =
   z.custom<SignupScreenerRejectedData>(defaultDataValidator);
const inNetworkCheckDataSchema =
   z.custom<InNetworkCheckData>(defaultDataValidator);
const triageScreenerStartedDataSchema =
   z.custom<TriageScreenerStartedData>(defaultDataValidator);
const triageScreenerFinishedDataSchema =
   z.custom<TriageScreenerFinishedData>(defaultDataValidator);
const audioPlayerErrorDataSchema =
   z.custom<AudioPlayerErrorEventData>(defaultDataValidator);
const dosingSessionStartedDataSchema =
   z.custom<DosingSessionStartedData>(defaultDataValidator);
const signupPaymentCompleteDataSchema =
   z.custom<SuccessfulPaymentEventFields>();
const initialScreeningCompletedBrowserWindowClosedDataSchema =
   z.custom<InitialScreeningCompletedBrowserWindowClosedEventData>();
const purchaseSelectProductDataSchema =
   z.custom<PurchaseSelectProductEventData>();
const purchaseUpsellClickAddTherapyDataSchema =
   z.custom<PurchaseUpsellClickAddTherapyEventData>();
const checkoutClickPaymentMethodDataSchema =
   z.custom<CheckoutClickPaymentMethodEventData>();
const checkoutDiscountCodeInputDataSchema =
   z.custom<CheckoutDiscountCodeInputEventData>();
const checkoutAddressValidationErrorDataSchema =
   z.custom<CheckoutAddressValidationErrorEventData>();
const checkoutClickPayDataSchema = z.custom<CheckoutClickPayEventData>();
const checkoutClickPurchaseButtonDataSchema =
   z.custom<CheckoutClickPurchaseButtonEventData>();
const patientStartedPurchaseFlowDataSchema =
   z.custom<PatientStartedPurchaseFlowEventData>();
const cancelAppointmentConfirmedEventData =
   z.custom<CancelAppointmentConfirmedEventData>();

const eventTrackingParam = {
   type: z
      .union([z.literal('event'), z.literal('page'), z.literal('screen')])
      .optional(),
   timestamp: z.number().int().optional(),
};

const eventsSchema = z.union([
   z.object({
      name: z.literal(CustomerIOEvents.AppointmentScheduled),
      data: appointmentEventDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.AppointmentCanceled),
      data: appointmentEventDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.AppointmentUpdateError),
      data: appointmentUpdateErrorDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PatientMissedAppointment),
      data: appointmentEventDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignupScreenerStep),
      data: signupScreenerStepDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignupAccountCreationComplete),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignOut),
   }),

   z.object({
      name: z.literal(CustomerIOEvents.SignupScreenerStarted),
      data: signupScreenerStartedDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignupScreenerRejected),
      data: signupScreenerRejectedDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.InNetworkCheck),
      data: inNetworkCheckDataSchema,
   }),

   z.object({
      name: z.literal(CustomerIOEvents.TriageScreenerStarted),
      data: triageScreenerStartedDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.TriageScreenerFinished),
      data: triageScreenerFinishedDataSchema,
   }),

   z.object({
      name: z.literal(CustomerIOEvents.AudioPlayerError),
      data: audioPlayerErrorDataSchema,
   }),

   z.object({
      name: z.literal(CustomerIOEvents.DosingSessionStarted),
      data: dosingSessionStartedDataSchema,
   }),

   z.object({
      name: z.literal(CustomerIOEvents.OnboardingConsentSigned),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.OnboardingRejected),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignIn),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.OnboardingComplete),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.OnboardingStarted),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.UploadIdStarted),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.UploadIdFinished),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.SignupPaymentComplete),
      data: signupPaymentCompleteDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.InitialScreeningCompletedClickContinue),
   }),
   z.object({
      name: z.literal(
         CustomerIOEvents.InitialScreeningCompletedBrowserWindowClosed,
      ),
      data: initialScreeningCompletedBrowserWindowClosedDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.InsuranceClickYes),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.InsuranceClickNo),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseSelectProduct),
      data: purchaseSelectProductDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseClickAddAndContinue),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseClickBack),
   }),

   z.object({
      name: z.literal(CustomerIOEvents.PurchaseUpsellClickAddTherapy),
      data: purchaseUpsellClickAddTherapyDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseUpsellClickCheckout),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseUpsellClickSkip),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseUpsellClickBack),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PurchaseUpsellClickRemove),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PostPurchaseUpsellClickAddTherapy),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PostPurchaseUpsellClickSkip),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutClickPaymentMethod),
      data: checkoutClickPaymentMethodDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutDiscountCodeInput),
      data: checkoutDiscountCodeInputDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutDiscountCodeRemove),
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutAddressValidationError),
      data: checkoutAddressValidationErrorDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutClickPay),
      data: checkoutClickPayDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CheckoutClickPurchaseButton),
      data: checkoutClickPurchaseButtonDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.PatientStartedPurchaseFlow),
      data: patientStartedPurchaseFlowDataSchema,
   }),
   z.object({
      name: z.literal(CustomerIOEvents.CancelAppointmentConfirmed),
      data: cancelAppointmentConfirmedEventData,
   }),
]);

// export const baseParamsSchema = z.object({
//    type: z
//       .union([z.literal('event'), z.literal('page'), z.literal('screen')])
//       .optional(),
//    timestamp: z.number().int().optional(),

//    name: z.nativeEnum(CustomerIOEvents),
//    data: defaultDataValidator.optional(),
// });

export const baseParamsSchema = z.intersection(
   eventsSchema,
   z.object(eventTrackingParam),
);

export const trackEventParamsSchema = z.intersection(
   baseParamsSchema,
   z.object({
      id: z.string().optional(),
   }),
);

export const trackEventParamsBeforeSignupSchema = z.intersection(
   baseParamsSchema,
   z.object({
      anonymousId: z.string().optional(),
      email: z.string().email(),
      customer: customerParamsSchema.optional(),
   }),
);

export const trackAnonymousEventApiParamsSchema = z.intersection(
   trackEventParamsSchema,
   z.object({
      anonymousId: z.string().uuid(),
   }),
);

export const updateAnonymousCioUserSchema = z.object({
   email: z.string().email(),
   customer: customerParamsSchema.optional(),
});

export type UpdateAnonymousCioUserDto = {
   email: string;
   customer?: CustomerParams;
};

export type TrackEventParamsBeforeSignup<EventType> = BaseParams<EventType> & {
   email: string;
   anonymousId?: string;
   customer?: CustomerParams;
};

export type TrackAnonymousEventApiParams<EventType> =
   TrackEventParamsDto<EventType> & {
      anonymousId: string;
   };
