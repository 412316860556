import { z } from 'zod';
import { dateTimeField, intExtendedField } from '../common/zod.fields';

export const createAdminEncounterSchema = z.object({
   patientId: z.string().uuid(),
   startDate: dateTimeField,
   endDate: dateTimeField,
   locationId: intExtendedField,
   serviceId: intExtendedField,
});

export type CreateAdminEncounterDto = z.infer<
   typeof createAdminEncounterSchema
>;
