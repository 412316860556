import type { Prisma } from '@innerwell/prisma-app';
import type { GetFindResult } from '@prisma/client/runtime/library';

export const SERVICES_SELECT = {
   id: true,
   name: true,
   slug: true,
   order: true,
} satisfies Prisma.ServiceSelect;

const args = {
   select: SERVICES_SELECT,
};

export type ServiceResponseDto = GetFindResult<
   Prisma.$ServicePayload,
   typeof args,
   unknown
>;
