import type { Prisma } from '@innerwell/prisma-app';
import type { GetFindResult } from '@prisma/client/runtime/library';

export const TREATMENT_METHODS_SELECT = {
   id: true,
   name: true,
   slug: true,
   order: true,
} satisfies Prisma.ServiceSelect;

const args = {
   select: TREATMENT_METHODS_SELECT,
};

export type TreatmentMethodResponseDto = GetFindResult<
   Prisma.$TreatmentMethodPayload,
   typeof args,
   unknown
>;
